@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --themColor:#6420AA;
  --secondaryColor:#EBF400;
  --darkColor:#6420AA;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active{
  color:var(--secondaryColor);
}

.active-hover :hover{
  color:var(--secondaryColor) !important;
}




.primary-bg-color{
  background-color: var(--themColor)!important;
}
.primary-color{
  color: var(--themColor) !important;
}
.secondary-bg-color{
  background-color: var(--secondaryColor) !important;
}
.secondary-color{
  color: var(--secondaryColor) !important;
}
.menu-border-color{
  border-color: var(--darkColor);
}
/* Butons */
.primary-btn{
  border-width: 1px ;
  border-color: var(--themColor);
  color: var(--themColor);
}
/* .primary-btn :hover{
  border-width: 1px ;
  border-color: var(--darkColor);
  color: var(--darkColor);
  font-weight: bold !important;
} */